/* eslint-disable */

/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */


/**
 * BROWSER POLYFILLS
 */

/** ES6/7 features for Opera Mini, which is still >1% **/
import 'core-js/es/array';
import 'core-js/es/date';
import 'core-js/es/function';
import 'core-js/es/map';
import 'core-js/es/math';
import 'core-js/es/number';
import 'core-js/es/object';
import 'core-js/es/parse-float';
import 'core-js/es/parse-int';
import 'core-js/es/regexp';
import 'core-js/es/set';
import 'core-js/es/string';
import 'core-js/es/symbol';

/** ES7 features */
import 'core-js/es/object/entries';
import 'core-js/es/object/values';

/** zone.js included with Angular CLI */
import 'zone.js'; //


/** Needed for .scrollIntoView({ behavior: 'smooth' }) on a few >1% mobile browsers */
import smoothscroll from 'smoothscroll-polyfill';


/**
 * APPLICATION IMPORTS
 */

/**
 * Provide "global" as a synonym for "window": https://stackoverflow.com/a/50214806/462235
 * Required by dragula due to a bug: https://github.com/valor-software/ng2-dragula/issues/849
 */
(window as any).global = window;


/** Needed for .scrollIntoView({ behavior: 'smooth' }) on a few >1% mobile browsers */
smoothscroll.polyfill();
